export const BUN = 'Булки';
export const SAUCE = 'Соусы';
export const MAIN = 'Начинки';
export const ENTER = 'Вход';
export const LOGIN = 'Войти';
export const RECOVER = 'Восстановить';
export const REGISTER = 'Зарегистрироваться';
export const REGISTRATION = 'Регистрация';
export const RESTORE_PASSWORD = 'Восстановить пароль';
export const PASWORD_RESTORATION = 'Восстановление пароля';
export const SAVE = 'Сохранить';
export const PROFILE = 'ПРОФИЛЬ';
export const ORDER_HISTORY = 'ИСТОРИЯ ЗАКАЗОВ';
export const EXIT = 'ВЫХОД';
export const BURGER_CONSTRUCTOR = 'Конструктор';
export const ORDER_FEED = 'Лента заказов';
export const USER_ACCOUNT = 'Личный кабинет';
export const FEED = 'Лента заказов';
export const COMPOUND = 'Состав:';
export const DONE = 'Выполнен';
export const IN_PROGRESS = 'Готовится';
export const CONNECTION_ERROR_WARNING = 'Ошибка соединения!';
export const COMPLETED_ALL_THE_TIME = 'Выполнено за все время:';
export const COMPLETED_TODAY = 'Выполнено за сегодня:';
export const COMPLETED = 'Готовы:';
export const ORDERS_IN_PROGRESS = 'В работе:';
export const DROP_INGREDIENTS_HERE = 'Перетащите сюда ингредиенты';
export const SOMETHING_WENT_WRONG = 'Kажется что-то пошло не так';
export const USER_DATA_UPDATED_SUCCESSFULLY =
  'Данные пользователя обновлены успешно!';
export const ARE_YOU_A_NEW_USER = 'Вы - новый пользователь?';
export const FORGOT_YOUR_PASSWORD = 'Забыли пароль?';
export const HERE_YOU_CAN_CHANGE_YOUR_PERSONAL_DATA = 'В этом разделе вы можете изменить свои персональные данные'
